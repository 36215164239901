import React, { useState } from "react";
import DataTable from "../components/DataTable";
import * as XLSX from "xlsx";
import { FaSearch } from "react-icons/fa";
import DaybookTable from "../components/DaybookTable";
import { toast } from "react-toastify";

const DaybookReport = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    fromDate: "", toDate: ""
  })


  const fields = [
    { name: "transactions.trn.date", label: "Date" },
    { name: "transactions.trn.time", label: "Time" },
    { name: "contactName", label: "Contact Name" },
    { name: "transactions.trn.credit", label: "Deposit" },
    { name: "transactions.trn.debit", label: "Withdraw" },
    { name: "transactions.trn.bonus", label: "Bonus" },
    { name: "transactions.trn.totalCoin", label: "Total Coin" },
    { name: "transactions.trn.utr", label: "UTR" },
    { name: "transactions.trn.acHoldername", label: "Bank Holder Name" },
    { name: "transactions.trn.bankBalance", label: "Bank Balance" },
    { name: "transactions.trn.totalBankbalance", label: "Total Bank Balance" },
    { name: "transactions.trn.doneBy", label: "Done By" },
  ];


  let [depositList, setDepositList] = useState([]);

  const handleReport = () => {
    console.log("deposite", depositList)
    if (depositList.length === 0) {
      return toast.error("No Data Available")
    }
    // Prepare data for tables (data lists are defined)

    const depositData = depositList.map((item) => {
      const transaction = item.transactions.trn;
      console.log("item",item)
      return {
        contactName: item.contactName,
        // Extract other relevant fields from the transaction object here
        credit: transaction.credit,
        debit: transaction.debit,
        bonus: transaction.bonus,
        bankname: transaction.bankname,
        date: transaction.date,
        time: transaction.time,
        doneBy: transaction.doneBy,
        utr: transaction.utr,
      };
    });

    // Create separate worksheets
    const depositSheet = XLSX.utils.json_to_sheet(depositData);


    // Define worksheet names
    const depositSheetName = "Transactions";

    // Create workbook and add sheets
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, depositSheet, depositSheetName);

    // Add header rows (optional)
    XLSX.utils.sheet_add_aoa(depositSheet, [[]], { origin: 'A1' });

    const headerdepositData = Object.keys(depositData[0]); // columns name

    function calculateColumnWidths(headerData) {
      const wscols = [];

      // Calculate column widths based on header length
      for (let i = 0; i < headerData.length; i++) {
        wscols.push({ wch: headerData[i].length + 5 }); // Add a buffer of 5 characters
      }

      return wscols;
    }

    depositSheet["!cols"] = calculateColumnWidths(headerdepositData);

    // Convert workbook to binary string
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    // Create Blob object
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

    // Create URL for Blob object
    const url = URL.createObjectURL(blob);

    // Create anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = "daybook_report.xlsx";

    // Trigger click event
    a.click();
  };

  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  const GenerateReportButton = () => {
    const [tempDateFilter, setTempDateFilter] = useState({
      fromDate: "", toDate: ""
    })

    const handleDateChange = (e) => {
      const { name, value } = e.target;
      console.log(tempDateFilter);
      setTempDateFilter(prev =>
        ({ ...prev, [name]: value })
      )
    }

    const handleDateFilter = (e) => {
      e.preventDefault();
      setDateFilter(tempDateFilter)
      setTempDateFilter({})
      setIsSubmitted(!isSubmitted)
    }
    
    return (
      <>
        <div className="d-flex gap-3 filter_section">
          <div className="d-flex gap-2 align-item-center date_filter">

            <input value={tempDateFilter.fromDate} onChange={(e) => { handleDateChange(e) }} name="fromDate" class="form-control" type="date" />
            <div className="d-flex justify-content-center align-item-center">TO</div>
            <input value={tempDateFilter.toDate} onChange={(e) => { handleDateChange(e) }} name="toDate" id="startDate" class="form-control" type="date" />
            <button onClick={(e) => { handleDateFilter(e) }} style={{background:"#3bd440", padding:"0 8px", borderRadius:"5px"}} className="border-0 ">
              <FaSearch />
            </button>
          </div>
          <button type="button" className="btn btn-success" onClick={handleReport}>Generate Report</button>
        </div>
      </>
    )
  }

  return (
    <>
      <DaybookTable
        fields={fields}
        route={"customer/getDaybookReport"}
        setTableList={setDepositList}
        reload={isSubmitted}
        buttonComponent={<GenerateReportButton />}
        componentData={{ dateFilter, setDateFilter, setDepositList }}
      />

    </>
  );
};

export default DaybookReport;
