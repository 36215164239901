import React, { useEffect, useState } from "react";
import axios from "axios";
import getBaseUrl from "../services/connectionString";
import {  ToastContainer } from "react-toastify";
import DeleteConfirmationPopup from "./DeleteConfirmationPopup";
import UpdateCustomerModal from "./UpdateCustomerModal";
import { useCreateListContext } from "../context/CreateListContext";
import '../css/DataTable.css'
import Pagination from "./Pagination";
import useFocusNext from "../helpers/nextRef"
import { AccessibilityNewOutlined } from "@mui/icons-material";
import {toast} from 'react-hot-toast'


const DataTable = ({ editable, fields, route, reload, setTableList, buttonComponent, componentData, onManageAccess }) => {
  const [dataList, setDataList] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isEditing, setIsEditing] = useState({}); // Object to track edit mode for each item
  const [editedData, setEditedData] = useState({}); // Object to store temporary edited values

  const [tableReload, setTableReload] = useState(false); // Object to

  const [search, setSearch] = useState("");
  const [customerToDelete, setCustomerToDelete] = useState(null); // Stores customer object for deletion
  const [formData, setFormData] = useState();
  const deleteButton = React.createRef();
  const updateButton = React.createRef();
  const [loadedCustomer, setLoadedCustomer] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7); // Adjust as needed

  // Logic to calculate the indexes of items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalItems = filteredData.length;

  const focusNextRef = useFocusNext();

  const ContactTypeOptions = [
    { value: "Not Defined", label: "--Select--" },
    { value: "Client", label: "Client" },
    { value: "Staff", label: "Staff" },
    { value: "Vendor", label: "Vendor" },
  ];

  // Function to handle nested object access
  function getFieldValue(obj, path) {
    const fieldParts = path.split(".");
    let current = obj;
    for (const part of fieldParts) {
      if (!current || typeof current !== "object") {
        return ""; // Handle missing nested properties gracefully
      }
      current = current[part];
    }

    return current ? current.toString() : ""; // Convert to string if not undefined
  }

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const storedCustomer = await localStorage.getItem("currentCustomer");
        if (storedCustomer) {
          const parsedCustomer = JSON.parse(storedCustomer);
          setLoadedCustomer(parsedCustomer);
          // console.log("Loaded customer:", parsedCustomer);
        }
      } catch (error) {
        // toast.error(error.response.data.message)
        // console.error("Error fetching customer data:", error);
      }
    };
    fetchCustomer()
    // console.log(loadedCustomer)
  }, []);

  // Handle search
  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    const filteredData = dataList.filter((item) =>
      fields.some((field) =>
        String(getFieldValue(item, field.name))
          .toLowerCase()
          .includes(searchValue)
      )
    );
    setFilteredData(filteredData);
  };

  const handleCustomerDelete = async (item) => {
    const response = await axios.delete(
      getBaseUrl() + `api/customer/deleteCustomer/${item._id}`,
      { headers: { "Content-Type": "application/json" } }
    );
    if (response.data.result) {
      // Successful update
      toast.success(response.data.message)
      deleteButton.current.click()
      const updatedData = filteredData.filter((i) => i._id !== item._id);
      setFilteredData(updatedData);
    } else {
      // toast.error(response.data.message)
    }
  }

  const handleCustomerUpdate = async (item) => {
    if (item) {
      try {
        const response = await axios.patch(
          getBaseUrl() + `api/customer/updateCustomer/${item._id}`,
          { formData: item },
          { headers: { "Content-Type": "application/json" } }
        );
        if (response.data.success) {
          // Successful update
          toast.success(response.data.message || 'Customer Updated successfully')
          setTableReload(!tableReload)
          // const updatedData = filteredData.map((existingItem) =>
          //   existingItem._id === item._id ? response.data || item : existingItem // Update or keep existing item
          // );
          // setFilteredData(updatedData);
          // setEditedData({})
        } else {
          // toast.error(response.data.message)
        }
      } catch (error) {
        // toast.error(error.response.data.message)

      }

    }

  }

  // const handleTransactionUpdate = async (item) => {
  //   console.log(item)
  //   if (item) {
  //     const response = await axios.patch(
  //       getBaseUrl() + `api/customer/updateTransaction`,
  //       { formData: item },
  //       { headers: { "Content-Type": "application/json" } }
  //     );
  //     if (response.status === 200) {
  //       // Successful update
  //       toast.success(response.data.message || 'Transaction Updated successfully')
  //       setTableReload(!tableReload)
  //       setEditedData({})
  //     } else {
  //       toast.error(response.data.message)
  //     }
  //   }

  // }

  const handleCustomerAdd = async (item) => {
    const validateStep1 = () => {
      let isValid = true;

      if (!item.contactName?.trim()) {
        toast.error(`Contact Name is required`);
        isValid = false;
      }

      // if (!item.phoneNumber?.trim()) {
      //   toast.error(`PhoneNumber is required`);
      //   isValid = false;
      // }

      // if (!item.contactType?.trim()) {
      //   toast.error(`Contact Type is required`);
      //   isValid = false;
      // }

      // if (item['paymentDetails.bankDetails.ifsc']?.length !== 11) {
      //   toast.error("IFSC Code should be 11 charecter");
      //   isValid = false;
      // }
      // if (!item['paymentDetails.bankDetails.accountHolder']?.trim()) {
      //   toast.error("Account Holder Name is required");
      //   isValid = false;
      // }

      // if (!item.customerBalance?.trim()) {
      //   toast.error("Customer Balance is required");
      //   isValid = false;
      // }
      // if (!item?.['paymentDetails.walletDetails.walletPhoneNo']?.trim() && !item?.['paymentDetails.bankDetails.bankAic']?.trim() && !item?.['paymentDetails.upiDetails']?.trim()) {
      //   toast.error("Any Bank Detail is required");
      //   isValid = false;
      // }


      return isValid;
    };
    if (validateStep1()) {
      try {
        const response = await axios.post(getBaseUrl() + 'api/customer/addCustomerTable', {
          formData: item,
        }, {
          headers: { 'Content-Type': 'application/json' },
        });

        if (response.data.result) {
          toast.success(response.data.message || 'Customer added successfully'); // Optional success message

          const newCustomer = response.data.newCustomer; // Access the newly created customer data

          // Update filtered data (consider different approaches):
          // Prepend the new customer to the beginning
          setFilteredData([newCustomer, ...filteredData]);
          setEditedData({});

          for (const fieldName in item) {
            document.getElementById(`empty-${fieldName}`).value = "";
            document.getElementById(`empty-${fieldName}`).style.width = "100px";
          }

        } else {
          // toast.error(response.data.message || 'Failed to add customer'); // Optional error message
        }
      } catch (error) {
        // toast.error(error.response.data.message || 'Failed to add customer'); // Optional error message
        console.error(error);
      }
    }
  };

  // const handleCustomerTransaction = async (item) => {

  //   axios.defaults.withCredentials = true;
  //   try {
  //     const res = await axios.post(
  //       getBaseUrl() + "api/customer/transaction",
  //       { formData: item },
  //       {
  //         withCredentials: true,
  //       }
  //     )
  //     // const data = res.json();
  //     console.log(res.data);

  //     if (res.data.result) {
  //       setEditedData({});
  //       toast.success(res.data.message || 'Transaction added successfully');
  //       const newCustomer = res.data.data; // Access the newly created customer data
  //       // Update filtered data (consider different approaches):
  //       // Prepend the new customer to the beginning
  //       setFilteredData([newCustomer, ...filteredData]);


  //     } else {
  //       toast.error(res.data.message || 'Failed to add transaction');
  //     }
  //   } catch (error) {
  //     toast.error(error.response.data.message);
  //     console.log(error.response.data.message);
  //   }

  // }
  // Function to handle row actions (update/delete)
  const handleRowAction = async (action, item) => {
    try {
      if (action === 'update') {
        setIsEditing((prevEditing) => ({ ...prevEditing, [item._id]: false })); // Exit edit mode
        handleCustomerUpdate(editedData[item._id]); // Pass edited data
        setEditedData({})
        // setFilteredData((prevData) =>
        //   prevData.map((i) => (i._id === item._id ? editedData[item._id] : i))
        // );

      } else if (action === 'add') {
        // Clear edited data (optional)
        handleCustomerAdd(item)
        setEditedData({});
        for (const fieldName in item) {
          document.getElementById(`empty-${fieldName}`).value = "";
          document.getElementById(`empty-${fieldName}`).style.width = "100px";
        }

      }

      else if (action === 'delete') {
        // console.log("delete")
        deleteButton.current.click();
        setCustomerToDelete(item); // Store customer object for deletion
      }
      // else if (action === 'addTrn') {


      //   // if(item.transactions.trn.debit && item.transactions.trn.credit && item.transactions.trn.bonus && item.contactName && item.transactions.trn.doneNy){
      //   handleCustomerTransaction(item)
      //   setEditedData({});
      //   for (const fieldName in item) {
      //     document.getElementById(`empty-${fieldName}`).value = "";
      //     document.getElementById(`empty-${fieldName}`).style.width = "100px";
      //   }
      //   // }else  return toast.error("All fienld are mandatory")
      // }
      // else {
      //   if (action === 'edit') {
      //     setIsEditing((prevEditing) => ({ ...prevEditing, [item.transactions.trn.trn_id]: false }));
      //     // console.log(item.transactions.trn.trn_id)
      //     // console.log("edited data", editedData)
      //     handleTransactionUpdate(editedData[item.transactions.trn.trn_id])
      //   }
      //   let formData = { activeAction: action, trn_id: item.transactions.trn.trn_id }
      //   const response = await axios.patch(
      //     getBaseUrl() + `api/customer/update-active-action/${item._id}`,
      //     { formData: formData },
      //     { headers: { "Content-Type": "application/json" } }
      //   );

      //   if (response.status === 200) {
      //     // Successful update

      //     // console.log(`Updated active action to ${action} for item ${item._id}`);
      //     // const updatedItem = response.data; // Create a copy with updated action
      //     // console.log("I", item)
      //     // console.log("UI", updatedItem)
      //     // const updatedData = filteredData.map((i) => (i.transactions.trn.trn_id === item.transactions.trn.trn_id ? updatedItem : i));
      //     // setFilteredData(updatedData);
      //     setTableReload(!tableReload)
      //     // toast.success("Updated")
      //   } else {
      //     console.error("Error updating active state:", response.statusText);
      //     // Handle update error (e.g., display error message)
      //   }
      // }

    } catch (error) {
      console.error("Error Handling Row Action:", error);
      // Handle unexpected errors
    }
  };


  const { addSelectedItem, removeSelectedItem, selectedItems } =
    useCreateListContext();
  const handleAddItem = (item) => {
    addSelectedItem(item);
  };

  const isItemAdded = (item) => selectedItems.includes(item);

  // Button label based on item selection
  const getListButtonLabel = (item) => (isItemAdded(item) ? "Remove" : "Add");

  // Button onClick handler based on item selection
  const handleListButtonClick = (item) => {
    isItemAdded(item) ? removeSelectedItem(item) : handleAddItem(item);
  };


  const isActive = (action, item) => {
    // Implement logic based on your retrieved data (e.g., item.activeAction)
    return item.transactions.trn.activeAction === action;
  };

  const handleEditChange = (itemId, fieldName, newValue) => {
    // Handle edits for the empty row (itemId === "empty")
    // console.log(editedData)
    if (itemId === "empty") {
      setEditedData((prevData) => ({ ...prevData, [fieldName]: newValue }));
      // Additional actions for empty row edits (optional)
    } else {
      setEditedData((prevData) => ({
        ...prevData,
        [itemId]: {
          _id: itemId, // Include _id for mapping
          ...prevData[itemId],
          [fieldName]: newValue,
        },
      }));
    }
    // Update input width based on new value
    // const estimatedWidth = getTextWidth(newValue || getFieldValue(itemId, fieldName)) + 10; // Use initial value or existing data
    // document.getElementById(`${itemId}-${fieldName}`).style.width = `${estimatedWidth}px`;

  };

  // const getTextWidth = (text) => {
  //   // Create a temporary element to measure text width
  //   const element = document.createElement('span');
  //   element.textContent = text;
  //   document.body.appendChild(element);
  //   const width = element.offsetWidth;
  //   document.body.removeChild(element);
  //   return width;
  // };

  const getBackgroundColor = (item) => {
    try {
      return isActive("verify", item) ? { backgroundColor: "#90ee90" } : isActive("notverify", item) ? { backgroundColor: "#ffc6c4" } : isActive("edit", item) ? { backgroundColor: "#ffc6c4" } : {}
    }
    catch (error) {

    }
  }



  useEffect(() => {
    // if (componentData?.dateFilter?.toDate && componentData?.dateFilter?.fromDate) {
    //   const filterDataByDateRange = (data, startDate, endDate) => {
    //     return data.filter(item => {
    //       // Extract the date from the datetime string
    //       const itemDate = new Date(item.transactions.trn.datetime).getTime();
    //       // Convert start and end dates to milliseconds
    //       const start = new Date(startDate).getTime();
    //       const end = new Date(endDate).getTime() + 24 * 60 * 60 * 1000;
    //       // Check if the item's date is within the range
    //       return itemDate >= start && itemDate <= end;
    //     });
    //   };
    //   const newData = filterDataByDateRange(dataList, componentData?.dateFilter.fromDate, componentData?.dateFilter.toDate)
    //   setFilteredData(newData);
    //   setCurrentPage(1)
    //   componentData?.setDepositList && componentData.setDepositList(newData)

    // } else {
    axios.defaults.withCredentials = true;
    axios
      .get(getBaseUrl() + `api/${route}`, {
        withCredentials: true,
      })
      .then((response) => {
        setDataList(response.data);
        setFilteredData(response.data);
        setTableList?.(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  }, [route, setTableList, reload, tableReload]);

  const [bankData, setBankData] = useState([])
  const [doneByData, setDoneByData] = useState([])

  const getBankList = async () => {
    try {
      // const res = await axios.get(getBaseUrl() + `api/bank/getActiveBanks`)
      const res = await axios.get(getBaseUrl() + `api/bank/getBankList`)
      setBankData([{ bankname: "--Select--" }, ...res.data]);
    } catch (error) {
      console.log(error)
    }
  }

  const getDoneByList = async () => {
    try {
      const res = await axios.get(getBaseUrl() + `api/customer/getStaffList`)
      setDoneByData([{ contactName: "--Select--" }, ...res.data]);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getBankList();
    getDoneByList();
  }, [])

  // delete item form list handler
  const deleteFromListHandler = async (e, id, item) => {
    // console.log("delete", id, item._id)

    try {
      const res = await axios.get(getBaseUrl() + `api/group/deleteGroupItem/${id}/${item._id}`)
      // console.log(res.data.items)
      if (res.status === 200) {
        toast.success("Item deleted successfully")
        setFilteredData(res.data.items)
      }
    } catch (error) {
      // toast.error("Error while deleting")
    }
  }

  const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup function to clear interval on unmount
  }, []);

  {/* item.transactions.trn.trn_id ? item.transactions.trn.trn_id : item._id */ }
  return (
    <>
      <div className="d-flex justify-content-between mt-3 responsive_layout" >
        <input
          type="text"
          className="form-control "
          style={{ "width": "25vw", "outline": "solid 1px", "outlineColor": "black" }}
          value={search}
          onChange={handleSearch}
          placeholder="Enter your search here"
          name="search"
          id="search"
        />
        <span >
          {
            buttonComponent ? buttonComponent : ""
          }
        </span>
      </div>

      <div className="table-responsive overflow-x-scroll">
        <table className="table align-middle table-striped">
          <thead className="text-center">
            <tr>
              {fields.map((field) => (
                <th scope="col" key={field.name}>
                  {field.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="text-center">
            {/* First row - always editable and empty */}

            {editable ? <tr>
              {fields.map((field) => (field.name !== "actions" && field.name !== "verify" && field.name !== "add" && field.name !== "delete") && (
                <td key={field.name}>
                  {field.name === "contactType" ? (
                    <select
                      id={`empty-${field.name}`} // Unique ID for first row
                      style={{ width: "100px", fontSize: "0.9em" }}
                      name="contactType"
                      className="input_selector form-select"
                      ref={focusNextRef}
                      value={editedData["empty"]?.[field.name]}
                      // value={editedData["contactType"] || "Not Defined"}
                      onChange={(e) => handleEditChange("empty", field.name, e.target.value)}
                    >
                      {ContactTypeOptions.map((val) => {
                        return (
                          <option key={val.label} value={val.value}>
                            {val.label}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    field.name === "transactions.trn.bankname" ? (
                      <>
                        <input
                          id={`empty-${field.name}`} // Unique ID for first row
                          ref={focusNextRef}
                          style={{ width: "100px" }}
                          className="input_selector form-select"
                          value={editedData["empty"]?.[field.name]}
                          list={"banknameOptions"}
                          // value={editedData["contactType"] || "Not Defined"}
                          onChange={(e) => handleEditChange("empty", field.name, e.target.value)}
                        />

                        <datalist id="banknameOptions">
                          {bankData.map((val) => {
                            return (
                              <option key={val.bankname} value={val.bankname}>
                                {val.bankname}
                              </option>
                            );
                          })}
                        </datalist>
                        {/* </select> */}
                      </>
                    ) : (
                      field.name === "transactions.trn.date" ? (
                        <input
                          // type="date"
                          disabled
                          className="input_selector form-control p-0"
                          ref={focusNextRef}
                          id={`empty-${field.name}`} // Unique ID for first row
                          style={{ width: "100px" }} // Initial width
                          // value={editedData["transactions.trn.date"] ? editedData["transactions.trn.date"] : new Date()} // Or getInitialValue(field.name)
                          // value={editedData["empty"]?.[field.name]}
                          value={new Date().toLocaleDateString()}
                          onChange={(e) => handleEditChange("empty", field.name, e.target.value)} // Handle changes for empty row
                        />
                      ) : (
                        field.name === "transactions.trn.time" ? (
                          <input
                            // type="time"
                            disabled
                            className="input_selector form-control"
                            ref={focusNextRef}
                            id={`empty-${field.name}`} // Unique ID for first row
                            style={{ width: "100px", padding: "0" }} // Initial width
                            // value={editedData["empty"]?.[field.name]}
                            value={currentTime} // Or getInitialValue(field.name)
                            onChange={(e) => handleEditChange("empty", field.name, e.target.value)} // Handle changes for empty row
                          />
                        ) : (
                          field.name === "transactions.trn.doneBy" ? (
                            <>
                              <input
                                id={`empty-${field.name}`} // Unique ID for first row
                                ref={focusNextRef}
                                style={{ width: "100px" }}
                                className="input_selector form-select"
                                value={editedData["empty"]?.[field.name]}
                                list={"donebyOptions"}
                                // value={editedData["contactType"] || "Not Defined"}
                                onChange={(e) => handleEditChange("empty", field.name, e.target.value)}
                              />

                              <datalist id="donebyOptions">
                                {doneByData.map((val) => {
                                  return (
                                    <option key={val.contactName} value={val.contactName}>
                                      {val.contactName}
                                    </option>
                                  );
                                })}
                              </datalist>
                              {/* </select> */}
                            </>
                          )
                            : (
                              <input
                                type="text"
                                maxLength={field.name === "ifsc" && 11}
                                ref={focusNextRef}
                                className="input_selector form-control"
                                id={`empty-${field.name}`} // Unique ID for first row
                                // style={{ width: "100px" }} // Initial width
                                value={editedData["empty"]?.[field.name]} // Or getInitialValue(field.name)
                                onChange={(e) => handleEditChange("empty", field.name, e.target.value)} // Handle changes for empty row
                              />
                            )
                        )
                      )
                    )
                  )
                  }

                </td>
              ))}
              {/* Conditionally render button column cells if there's an "actions" field */}
              {fields.some((field) => field.name === "actions") && (
                <td key="actions">
                  <button
                    className="btn btn-sm btn-primary"
                    ref={focusNextRef}
                    onClick={() => handleRowAction("add", editedData)} // Handle adding a new row
                  >
                    <i class="bi bi-plus"></i>
                  </button>

                </td>
              )}
              {/* {fields.some((field) => field.name === "delete") && (
                <td key="delete">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => handleRowAction("addToList", editedData)} // Handle adding a new row
                  >
                    <i class="bi bi-plus"></i>
                  </button>
                </td>
              )} */}

              {/* Conditionally render verify buttons if needed for the first row */}
              {/* {fields.some((field) => field.name === "verify") && (
                <td key="verify">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => handleRowAction("addTrn", editedData)} // Handle adding a new row
                  >
                    <i class="bi bi-plus"></i>
                  </button>
                </td>
              )} */}


            </tr> : null}

            {currentItems.length !== 0 ? (
              currentItems.map((item, index) => (
                <tr key={index}>
                  {fields.map((field) => field.name !== "actions" && field.name !== "verify" && field.name !== "add" && field.name !== "delete" ? (
                    <td key={field.name} >
                      {isEditing[item?.transactions?.trn.trn_id || item._id] ? (

                        field.name === "contactType" ? (
                          <select
                            id={`${item._id}-${field.name}`}
                            style={{ width: "100px" }}
                            value={editedData[item._id]?.[field.name] || getFieldValue(item, field.name)}
                            onChange={(e) => handleEditChange(item._id, field.name, e.target.value)}
                          >
                            {ContactTypeOptions.map((val) => {
                              return (
                                <option key={val.label} value={val.value}>
                                  {val.label}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          field.name === "transactions.trn.bankname" ? (
                            <>
                              <input
                                style={{ width: "100px" }}
                                className="input_selector form-select"
                                list={"banknameOptions"}
                                // value={editedData["contactType"] || "Not Defined"}
                                value={editedData[isEditing[item.transactions.trn.trn_id] ? item.transactions.trn.trn_id : item._id]?.[field.name] || getFieldValue(item, field.name)}
                                id={`${item._id}-${field.name}`}
                                onChange={(e) => handleEditChange(isEditing[item.transactions.trn.trn_id] ? item.transactions.trn.trn_id : item._id, field.name, e.target.value)}

                              />

                              <datalist id="banknameOptions">
                                {bankData.map((val) => {
                                  return (
                                    <option key={val.bankname} value={val.bankname}>
                                      {val.bankname}
                                    </option>
                                  );
                                })}
                              </datalist>
                              {/* </select> */}
                            </>
                          ) : (
                            field.name === "transactions.trn.date" ? (
                              <input
                                // type="date"
                                disabled
                                className="input_selector form-control p-0"
                                style={{ width: "100px" }} // Initial width
                                // value={editedData["transactions.trn.date"] ? editedData["transactions.trn.date"] : new Date()} // Or getInitialValue(field.name)
                                // value={editedData["empty"]?.[field.name]}
                                id={`${item._id}-${field.name}`}
                                onChange={(e) => handleEditChange(isEditing[item.transactions.trn.trn_id] ? item.transactions.trn.trn_id : item._id, field.name, e.target.value)}

                                value={new Date().toLocaleDateString()}
                              />
                            ) : (
                              field.name === "transactions.trn.time" ? (
                                <input
                                  // type="time"
                                  disabled
                                  className="input_selector form-control"
                                  style={{ width: "100px", padding: "0" }} // Initial width
                                  // value={editedData["empty"]?.[field.name]}
                                  value={currentTime} // Or getInitialValue(field.name)
                                  id={`${item._id}-${field.name}`}
                                  onChange={(e) => handleEditChange(isEditing[item.transactions.trn.trn_id] ? item.transactions.trn.trn_id : item._id, field.name, e.target.value)}
                                />
                              ) : (
                                field.name === "transactions.trn.doneBy" ? (
                                  <>
                                    <input
                                      style={{ width: "100px" }}
                                      className="input_selector form-select"
                                      list={"donebyOptions"}
                                      // value={editedData["contactType"] || "Not Defined"}
                                      value={editedData[isEditing[item.transactions.trn.trn_id] ? item.transactions.trn.trn_id : item._id]?.[field.name] || getFieldValue(item, field.name)}
                                      id={`${item._id}-${field.name}`}
                                      onChange={(e) => handleEditChange(isEditing[item.transactions.trn.trn_id] ? item.transactions.trn.trn_id : item._id, field.name, e.target.value)}
                                    />

                                    <datalist id="donebyOptions">
                                      {doneByData.map((val) => {
                                        return (
                                          <option key={val.contactName} value={val.contactName}>
                                            {val.contactName}
                                          </option>
                                        );
                                      })}
                                    </datalist>
                                    {/* </select> */}
                                  </>
                                ) : (
                                  <input
                                    type="text"
                                    maxLength={field.name === "ifsc" && 11}
                                    className="input_selector form-control"
                                    style={{ width: "100px" }} // Initial width
                                    value={editedData[isEditing[item.transactions.trn.trn_id] ? item.transactions.trn.trn_id : item._id]?.[field.name] || getFieldValue(item, field.name)}
                                    id={`${item._id}-${field.name}`}
                                    onChange={(e) => handleEditChange(isEditing[item.transactions.trn.trn_id] ? item.transactions.trn.trn_id : item._id, field.name, e.target.value)}
                                  />
                                )
                              )
                            )
                          )
                        )

                      ) : (
                        <span>{getFieldValue(item, field.name) || "--"}</span>
                      )}
                    </td>
                  ) : null)}
                  {/* Conditionally render button column cells if there's an "actions" field */}
                  {fields.some((field) => field.name === "actions") && (
                    <td key="actions" className="d-flex justify-content-center gap-2">
                      {isEditing[item._id] ? (
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={() => handleRowAction("update", item)}
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>) : (<button
                          className="btn btn-sm btn-primary"
                          onClick={() => setIsEditing((prevEditing) => ({ ...prevEditing, [item._id]: true }))}
                        >
                          <i class="bi bi-pencil-square"></i>
                        </button>)}
                      {" "}
                      {/* {console.log(item.contactType)} */}
                      {item.contactType === 'Staff' && !loadedCustomer && (
                        <button
                          className="btn btn-sm btn-info"
                          onClick={() => onManageAccess(item)}
                        >
                          <AccessibilityNewOutlined/>
                        </button>
                      )
                      }
                      {" "}
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleRowAction("delete", item)}
                      >
                        <i class="bi bi-trash"></i>
                      </button>
                    </td>
                  )}
                  {/* Button for add to checklist */}
                  {fields.some((field) => field.name === "add") && (
                    <td key="add">
                      <button
                        className={`btn btn-sm btn-${isItemAdded(item) ? "danger" : "primary"
                          }`}
                        onClick={() => handleListButtonClick(item)}
                      >
                        {getListButtonLabel(item)}
                      </button>
                    </td>
                  )}
                  {fields.some((field) => field.name === "delete") && (
                    <td key="delete">
                      <button
                        onClick={(e) => deleteFromListHandler(e, componentData?.id, item)}
                        className={`btn btn-sm btn-danger`}
                      >
                        Delete
                      </button>
                    </td>
                  )}


                  {/* {fields.some((field) => field.name === "verify") && (
                    <td key="verify">
                      <button
                        className={`btn btn-sm ${isActive("verify", item) ? "btn-success" : "btn-outline-success"
                          }`}
                        onClick={() => handleRowAction("verify", item)}
                      >
                        <i class="bi bi-check2"></i>
                      </button>{" "}
                      <button
                        className={`btn btn-sm ${isActive("notverify", item) ? "btn-danger" : "btn-outline-danger"
                          }`}
                        onClick={() => handleRowAction("notverify", item)}
                      >
                        <i class="bi bi-x-lg"></i>
                      </button>{" "}
                      {
                        isEditing[item.transactions.trn.trn_id] ? (
                          <button
                            className={`btn btn-sm ${isActive("edit", item) ? "btn-danger" : "btn-outline-info"
                              }`}
                            onClick={() => handleRowAction("edit", item)}
                          >
                            <i class="bi bi-pencil-square"></i>
                          </button>
                        ) : (
                          <button
                            className={`btn btn-sm ${isActive("edit", item) ? "btn-danger" : "btn-outline-info"
                              }`}
                            onClick={() => setIsEditing((prevEditing) => ({ ...prevEditing, [item.transactions.trn.trn_id]: true }))}
                          >
                            <i class="bi bi-pencil-square"></i>
                          </button>
                        )
                      }

                    </td>
                  )} */}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={fields.length}>No data found</td>
              </tr>
            )}
          </tbody>
        </table>


      </div >

      <ul className="mt-4 pagination d-flex justify-content-center">
        <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalItems={totalItems} itemsPerPage={itemsPerPage} />
      </ul>


      <button
        className="supplier_btn"
        type="button"
        ref={deleteButton}
        data-bs-toggle="modal"
        data-bs-target="#deleteModal"
        style={{ display: 'none' }}
      >
      </button>

      <DeleteConfirmationPopup
        customerName={customerToDelete?.contactName} // Access name from customerToDelete
        onConfirm={() => {
          handleCustomerDelete(customerToDelete);
        }}
        onCancel={() => deleteButton.current.click()}
      />
      <button
        className="supplier_btn"
        type="button"
        ref={updateButton}
        data-bs-toggle="modal"
        data-bs-target="#updateModal"
        style={{ display: 'none' }}
      >
      </button>

      <UpdateCustomerModal onSubmit={handleCustomerUpdate} data={formData} />
      <ToastContainer/>
    </>
  );
};

export default DataTable;

