const local_String = "http://localhost:4000/";
const Production_String_Old =
  "https://saa306kjc7.execute-api.ap-south-1.amazonaws.com/Prod/";

  const Production_String =
  "https://c7qa8ceeu0.execute-api.ap-south-1.amazonaws.com/Prod/";

const getBaseUrl = () => {
  // return local_String;
  return Production_String;
};

export default getBaseUrl;
