import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from 'axios'
import getBaseUrl from "../services/connectionString";

const AddCustomerModel = ({ setTableReload }) => {
  const myButtonRef = React.createRef();

  const [formData, setFormData] = useState({
    contactName: "",
    phoneNumber: "",
    contactType: "",
    finalCustomerBalance: 0
  });

  const validateStep1 = () => {
    const { contactName, phoneNumber, contactType } = formData;
    let isValid = true;

    if (!contactName.trim()) {
      toast.error(`Contact Name is required`);
      isValid = false;
    }
    // const phoneRegex = new RegExp(/^[0-9\b]+$/);
    else if (phoneNumber.length !== 10) {
      toast.error(`Please enter 10 digits mobile number`);
      isValid = false;
    }
    else if (!contactType) {
      toast.error(`Account Type is required`);
      isValid = false;
    }
    else {
      isValid = true;
    }
    return isValid;
  };

  const options = [
    { value: undefined, label: "--Select--" },
    { value: "Client", label: "Client" },
    { value: "Staff", label: "Staff" },
    { value: "Vendor", label: "Vendor" },
  ];

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData, [name]: value,
    }));
  };

  const handleChangeSelect = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData, [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateStep1()) {
      try {
        const response = await axios.post(getBaseUrl() + 'api/customer/addCustomerTable', { formData: formData });

        if (response.data.result) {
          toast.success(response.data.message || 'Customer added successfully'); // Optional success message
          setFormData({
            contactName: "",
            phoneNumber: "",
            contactType: "",
            finalCustomerBalance: 0
          });
          setTableReload(prev => !prev)
          myButtonRef.current.click();
        } else {
          toast.error(response.data.message || 'Failed to add customer'); // Optional error message
        }
      } catch (error) {
        console.error(error);
        toast.error(error.response.data.message || 'Failed to add customer'); // Optional error message
      }
    }
  };

  return (
    <div>
      {/* {openModal ? ( */}
      <div
        className={`modal fade`}
        id="updateModal"
        tabIndex="-1"
        aria-labelledby="updateModal"
        style={{ zIndex: "99991" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Adding Customer Details
              </h5>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="contactName" className="form-label">
                    Customer Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="contactName"
                    placeholder="Enter Customer Name"
                    name="contactName"
                    // value={formData.step1.contactName}
                    onChange={handleChange}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="form-label">
                    Phone Number
                  </label>
                  <div className="modal_btn_number">
                    <button>+91</button>
                    <input
                      minLength={10}
                      maxLength={10}
                      type="tel"
                      className="form-control"
                      id="phoneNumber"
                      placeholder="Enter Phone Number"
                      name="phoneNumber"
                      // value={formData.step1.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="contactType" className="form-label">
                    Account Type
                  </label>
                  <div className="modal_btn_amount">
                    <select
                      className="w-100"
                      id="contactType"
                      name="contactType"
                      // value={formData.step1.contactType}
                      onChange={handleChangeSelect}
                    >
                      {options.map((val) => {
                        return (
                          <option key={val.label} value={val.value}>
                            {val.label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="mb-3">
                  <label htmlFor="number" className="form-label">
                    Balance
                  </label>
                  <div className="modal_btn_amount">
                    <button>₹</button>
                    <input
                      type="text"
                      className="form-control"
                      id="number"
                      name="finalCustomerBalance"
                      placeholder="Enter Amount"
                      // value={formData.step1.finalCustomerBalance}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={`btn btn-primary`}
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                ref={myButtonRef}
                data-bs-dismiss="modal"
                style={{ display: 'none' }}
              >
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* ) : null} */}
    </div>
  );
};

export default AddCustomerModel;
