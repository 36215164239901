import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import getBaseUrl from "../services/connectionString";
import { toast } from "react-toastify";
import '../css/Receivable.css'
import useFocusNext from "../helpers/nextRef"

import Pagination from "./Pagination";
import Select from 'react-select';
import AddCustomerModel from "./AddCustomerModel";


const DaybookTable = ({ editable, fields, route, reload, setTableList, buttonComponent, componentData }) => {
    const [dataList, setDataList] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isEditing, setIsEditing] = useState({}); // Object to track edit mode for each item
    const [editedData, setEditedData] = useState({}); // Object to store temporary edited values

    const [tableReload, setTableReload] = useState(false); // Object to

    const [search, setSearch] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(8); // Adjust as needed

    const totalItems = filteredData.length; // Example total items
    const selectRef = useRef(null);

    // Logic to calculate the indexes of items to display on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const focusNextRef = useFocusNext();


    // Function to handle nested object access
    function getFieldValue(obj, path) {
        const fieldParts = path.split(".");
        let current = obj;
        for (const part of fieldParts) {
            if (!current || typeof current !== "object") {
                return ""; // Handle missing nested properties gracefully
            }
            current = current[part];
        }

        return current ? current.toString() : ""; // Convert to string if not undefined
    }

    // Handle search
    const handleSearch = (e) => {
        const searchValue = e.target.value.toLowerCase();
        setSearch(searchValue);
        const filteredData = dataList.filter((item) =>
            fields.some((field) =>
                String(getFieldValue(item, field.name))
                    .toLowerCase()
                    .includes(searchValue)
            )
        );
        setFilteredData(filteredData);
    };

    const handleTransactionUpdate = async (item) => {
        if (item) {
            try {
                const response = await axios.patch(
                    getBaseUrl() + `api/customer/updateTransaction`,
                    { formData: item },
                    { headers: { "Content-Type": "application/json" } }
                );
                if (response.data.success) {
                    // Successful update
                    setTableReload(!tableReload)
                    toast.success(response.data.message || 'Transaction Updated successfully')

                } else {
                    toast.error(response.data.message)
                }
            } catch (error) {
                toast.error(error.response.data.message)

            }
        }

    }


    const handleCustomerTransaction = async (item) => {

        axios.defaults.withCredentials = true;
        try {
            const res = await axios.post(
                getBaseUrl() + "api/customer/transaction",
                { formData: item },
                {
                    withCredentials: true,
                }
            )
            // const data = res.json();
            console.log(res.data);

            if (res.data.result) {

                toast.success(res.data.message || 'Transaction added successfully');
                const newCustomer = res.data.data; // Access the newly created customer data
                // Update filtered data (consider different approaches):
                // Prepend the new customer to the beginning
                setFilteredData([newCustomer, ...filteredData]);

            } else {
                toast.error(res.data.message || 'Failed to add transaction');
            }

        } catch (error) {
            toast.error(error.response.data.message);
            console.log(error.response.data.message);
        }
    }
    // Function to handle row actions (update/delete)
    const handleRowAction = async (action, item) => {
        try {

            if (action === 'addTrn') {
                for (const fieldName in item) {
                    document.getElementById(`empty-${fieldName}`).value = "";
                    // document.getElementById(`empty-${fieldName}`).style.width = "100px";
                }
                setEditedData({});
                await handleCustomerTransaction(item)
            }
            else {
                if (action === 'edit') {
                    setIsEditing((prevEditing) => ({ ...prevEditing, [item.transactions.trn.trn_id]: false }));
                    handleTransactionUpdate(editedData[item.transactions.trn.trn_id])
                    setEditedData({})
                }

                let formData = { activeAction: action, trn_id: item.transactions.trn.trn_id }
                const response = await axios.patch(
                    getBaseUrl() + `api/customer/update-active-action/${item._id}`,
                    { formData: formData },
                    { headers: { "Content-Type": "application/json" } }
                );

                if (response.status === 200) {
                    setTableReload(!tableReload)
                    setEditedData({})
                    // toast.success("Updated")
                } else {
                    console.error("Error updating active state:", response.statusText);
                    // Handle update error (e.g., display error message)
                }
            }

        } catch (error) {
            console.error("Error Handling Row Action:", error);
            // Handle unexpected errors
        }
    };



    const isActive = (action, item) => {
        // Implement logic based on your retrieved data (e.g., item.activeAction)
        return item.transactions.trn.activeAction === action;
    };

    const handleEditChange = (itemId, fieldName, newValue) => {
        // Handle edits for the empty row (itemId === "empty")
        if (itemId === "empty") {
            if (fieldName === "transactions.trn.acHoldername") {

                // for bank balance
                axios.get(getBaseUrl() + `api/bank/bankBalance/${newValue}`).then((response) => {
                    if (response.data.success) {
                        let bankBalance = response.data.data.balance;
                        console.log("Bank Balance: " + bankBalance)
                        setEditedData((prevData) => ({ ...prevData, ["transactions.trn.bankBalance"]: bankBalance }));
                    }
                }).catch((error) => {
                    console.log(error);
                })

                // for total bank balance
                axios
                    .get(getBaseUrl() + `api/bank/totalBankbalance`, {
                    })
                    .then((response) => {
                        let totalBankbalance = response.data.totalBalance
                        console.log("Total Bank Balance: " + totalBankbalance)
                        setEditedData((prevData) => ({ ...prevData, ["transactions.trn.totalBankbalance"]: totalBankbalance }));

                    })
                    .catch((err) => {
                        console.log(err);
                    });

            }
            setEditedData((prevData) => ({ ...prevData, [fieldName]: newValue }));
            console.log(editedData);


            // Additional actions for empty row edits (optional)
        } else {
            setEditedData((prevData) => ({
                ...prevData,
                [itemId]: {
                    _id: itemId, // Include _id for mapping
                    ...prevData[itemId],
                    [fieldName]: newValue,
                },
            }));
        }
        console.log(editedData);
    };

    const getBackgroundColor = (item) => {
        try {
            return isActive("verify", item) ? { backgroundColor: "#90ee90" } : isActive("notverify", item) ? { backgroundColor: "#ffc6c4" } : isActive("edit", item) ? { backgroundColor: "#FFFF80" } : {}
        }
        catch (error) {

        }
    }

    const [bankData, setBankData] = useState([])
    const [doneByData, setDoneByData] = useState([])
    const [clientData, setClientData] = useState([])

    useEffect(() => {
        if (componentData?.dateFilter?.toDate && componentData?.dateFilter?.fromDate) {
            const filterDataByDateRange = (data, startDate, endDate) => {
                return data.filter(item => {
                    // Extract the date from the datetime string
                    const itemDate = new Date(item.transactions.trn.datetime).getTime();
                    // Convert start and end dates to milliseconds
                    const start = new Date(startDate).getTime();
                    const end = new Date(endDate).getTime() + 24 * 60 * 60 * 1000;
                    // Check if the item's date is within the range
                    return itemDate >= start && itemDate <= end;
                });
            };
            const newData = filterDataByDateRange(dataList, componentData?.dateFilter.fromDate, componentData?.dateFilter.toDate)
            setFilteredData(newData);
            setCurrentPage(1)
            componentData?.setDepositList && componentData.setDepositList(newData)

        } else {
            axios.defaults.withCredentials = true;
            axios
                .get(getBaseUrl() + `api/${route}`, {
                    withCredentials: true,
                })
                .then((response) => {
                    setDataList(response.data);
                    setFilteredData(response.data);
                    setTableList?.(response.data);
                })
                .catch((err) => {
                    console.log(err);
                });

        }
    }, [route, setTableList, reload, tableReload]);



    const getBankList = async () => {
        try {
            const res = await axios.get(getBaseUrl() + `api/bank/getActiveBankHolderName`)
            console.log(res.data)
            setBankData([...res.data]);
        } catch (error) {
            console.log(error)
        }
    }

    const getDoneByList = async () => {
        try {
            const res = await axios.get(getBaseUrl() + `api/customer/getStaffList`)
            setDoneByData([...res.data]);
        } catch (error) {
            console.log(error)
        }
    }
    const getClientList = async () => {
        try {
            const res = await axios.get(getBaseUrl() + `api/customer/getClientList`)
            setClientData([...res.data]);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getBankList();
        getDoneByList();
        getClientList();
    }, [tableReload])


    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());


    const customStyles = {
        control: (base) => ({
            ...base,
            width: "150px",
        }),
        menu: (base) => ({
            ...base,
            zIndex: 999999, // Ensuring the menu appears above other elements
        }),
    };


    return (
        <>
            <div className="d-flex justify-content-between my-3 mx-3 daybook_responsive_layout" >
                <span className="d-flex gap-4">
                    <input
                        type="text"
                        className="form-control "
                        style={{ "width": "25vw", "outline": "solid 1px", "outlineColor": "black" }}
                        value={search}
                        onChange={handleSearch}
                        placeholder="Enter your search here"
                        name="search"
                        id="search"
                    />
                    <button
                        className="btn btn-secondary m-0"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#updateModal"
                        tabIndex="0"
                    >
                        Add Customer
                    </button>
                    {/* <AddCustomerModel /> */}
                </span>
                <span >
                    {
                        buttonComponent ? buttonComponent : ""
                    }
                </span>
            </div>

            <div className="receivable_data_table" style={{ height: "70vh" }}>
                <div className="table-responsive">
                    <table className="table align-middle table-striped">
                        <thead className="text-center">
                            <tr>
                                {fields.map((field) => (
                                    <th style={{ width: "max-content" }} scope="col" key={field.name}>
                                        {field.label}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody className="text-center">
                            {/* First row - always editable and empty */}

                            {editable ? <tr>
                                {fields.map((field) => (field.name !== "actions" && field.name !== "verify" && field.name !== "add" && field.name !== "delete") && (
                                    <td key={field.name}>
                                        {field.name === "contactName" ? (
                                            <>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    // value={clientData?.contactName}
                                                    id={`empty-${field.name}`}
                                                    // ref={focusNextRef}
                                                    forwardRef={focusNextRef}
                                                    value={editedData[field.name] ? clientData.contactName : ""}
                                                    name="clientName"
                                                    options={clientData}
                                                    onChange={(selectedOption) => {
                                                        handleEditChange("empty", field.name, selectedOption.contactName)
                                                    }}
                                                    styles={customStyles}
                                                    getOptionLabel={(option) => option.contactName}
                                                    getOptionValue={(option) => option.contactName}
                                                />
                                            </>
                                        ) : (
                                            field.name === "transactions.trn.acHoldername" ? (
                                                <>
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        id={`empty-${field.name}`}
                                                        // value={bankData?.accountHolder}
                                                        value={editedData[field.name] ? bankData.accountHolder : ""}
                                                        name="bankName"
                                                        // ref={focusNextRef}
                                                        options={bankData}
                                                        onChange={(selectedOption) => handleEditChange("empty", field.name, selectedOption.accountHolder)}
                                                        styles={customStyles}
                                                        getOptionLabel={(option) => option.accountHolder}
                                                        getOptionValue={(option) => option.accountHolder}
                                                    />
                                                </>
                                            ) : (
                                                field.name === "transactions.trn.date" ? (
                                                    <input
                                                        type="date"
                                                        //disabled
                                                        className="input_selector form-control p-0"
                                                        ref={focusNextRef}
                                                        id={`empty-${field.name}`} // Unique ID for first row
                                                        style={{ width: "100px" }} // Initial width
                                                        value={editedData["transactions.trn.date"]
                                                            ? editedData["transactions.trn.date"]
                                                            : new Date().toISOString().slice(0, 10)}
                                                        // value={editedData["empty"]?.[field.name]}
                                                        //value={new Date().toLocaleDateString()}
                                                        onChange={(e) => handleEditChange("empty", field.name, e.target.value)} // Handle changes for empty row
                                                    />
                                                ) : (
                                                    field.name === "transactions.trn.time" ? (
                                                        <input
                                                            type="time"
                                                            //disabled
                                                            className="input_selector form-control"
                                                            ref={focusNextRef}
                                                            id={`empty-${field.name}`} // Unique ID for first row
                                                            style={{ width: "100px", padding: "0" }} // Initial width
                                                            // value={editedData["empty"]?.[field.name]}
                                                            value={editedData["transactions.trn.time"]
                                                                ? editedData["transactions.trn.time"]
                                                                : (new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }).slice(0, 8))}
                                                            //value={currentTime} // Or getInitialValue(field.name)
                                                            onChange={(e) => handleEditChange("empty", field.name, e.target.value)} // Handle changes for empty row
                                                        />
                                                    ) : (
                                                        field.name === "transactions.trn.doneBy" ? (
                                                            <>
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    id={`empty-${field.name}`}
                                                                    // value={doneByData?.contactName}
                                                                    value={editedData[field.name] ? doneByData.contactName : ""} name="doneByData"
                                                                    options={doneByData}
                                                                    // ref={focusNextRef}
                                                                    onChange={(selectedOption) => handleEditChange("empty", field.name, selectedOption.contactName)}
                                                                    styles={customStyles}
                                                                    getOptionLabel={(option) => option.contactName}
                                                                    getOptionValue={(option) => option.contactName}
                                                                />
                                                            </>
                                                        )
                                                            : (
                                                                field.name === "transactions.trn.totalBankbalance" || field.name === "transactions.trn.bankBalance" ? (

                                                                    <input
                                                                        type="text"
                                                                        className="input_selector form-control"
                                                                        ref={focusNextRef}
                                                                        id={`empty-${field.name}`} // Unique ID for first row
                                                                        style={{ width: "100px" }} // Initial width
                                                                        readOnly
                                                                        value={editedData?.[field.name]}
                                                                    // value={totalBankbalance} // Or getInitialValue(field.name)
                                                                    />
                                                                ) : (
                                                                    field.name === "transactions.trn.utr" || field.name === "transactions.trn.remark" ? (
                                                                        <input
                                                                            type="text"
                                                                            className="input_selector form-control"
                                                                            ref={focusNextRef}
                                                                            id={`empty-${field.name}`} // Unique ID for first row
                                                                            style={{ width: "100px" }} // Initial width
                                                                            value={editedData["empty"]?.[field.name]} // Or getInitialValue(field.name)
                                                                            onChange={(e) => handleEditChange("empty", field.name, e.target.value)} // Handle changes for empty row
                                                                        />
                                                                    ) : (
                                                                        <input
                                                                            type="text"
                                                                            className="input_selector form-control"
                                                                            id={`empty-${field.name}`} // Unique ID for first row
                                                                            ref={focusNextRef}
                                                                            style={{ width: "100px" }} // Initial width
                                                                            defaultValue={0}
                                                                            value={editedData["empty"]?.[field.name]} // Or getInitialValue(field.name)
                                                                            onChange={(e) => handleEditChange("empty", field.name, e.target.value)} // Handle changes for empty row
                                                                        />
                                                                    )
                                                                )
                                                            )
                                                    )
                                                )
                                            )
                                        )
                                        }

                                    </td>
                                ))}


                                {/* Conditionally render verify buttons if needed for the first row */}
                                {fields.some((field) => field.name === "verify") && (
                                    <td key="verify">
                                        <div style={{ width: "120px" }}>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                ref={focusNextRef}
                                                onClick={() => handleRowAction("addTrn", editedData)} // Handle adding a new row
                                            >
                                                <i class="bi bi-plus"></i>
                                            </button>
                                        </div>
                                    </td>
                                )}


                            </tr> : null}

                            {currentItems.length !== 0 ? (
                                currentItems.map((item, index) => (
                                    <tr key={index}>
                                        {fields.map((field) => field.name !== "actions" && field.name !== "verify" && field.name !== "add" && field.name !== "delete" ? (
                                            <td key={field.name} style={getBackgroundColor(item)}>
                                                {isEditing[item?.transactions?.trn.trn_id || item._id] ? (

                                                    field.name === "contactName" ? (
                                                        <>
                                                            <input
                                                                style={{ width: "100px" }}
                                                                className="input_selector form-select"
                                                                list={"ClientOptions"}
                                                                readOnly={true}
                                                                // value={editedData["contactType"] || "Not Defined"}
                                                                value={editedData[item.transactions.trn.trn_id]?.[field.name] || getFieldValue(item, field.name)}
                                                                id={`${item._id}-${field.name}`}
                                                                onChange={(e) => handleEditChange(item.transactions.trn.trn_id, field.name, e.target.value)}

                                                            />

                                                            <datalist id="ClientOptions">
                                                                {clientData.map((val) => {
                                                                    return (
                                                                        <option key={val.contactName} value={val.contactName}>
                                                                            {val.contactName}
                                                                        </option>
                                                                    );
                                                                })}
                                                            </datalist>

                                                        </>
                                                    ) : (
                                                        field.name === "transactions.trn.acHoldername" ? (
                                                            <>
                                                                {/* <input
                                                                    style={{ width: "100px" }}
                                                                    className="input_selector form-select"
                                                                    list={"banknameOptions"}
                                                                    // value={editedData["contactType"] || "Not Defined"}
                                                                    value={editedData[ item.transactions.trn.trn_id ]?.[field.name] || getFieldValue(item, field.name)}
                                                                    id={`${item._id}-${field.name}`}
                                                                    onChange={(e) => handleEditChange( item.transactions.trn.trn_id , field.name, e.target.value)}

                                                                />

                                                                <datalist id="banknameOptions">
                                                                    {bankData.map((val) => {
                                                                        return (
                                                                            <option key={val.accountHolder} value={val.accountHolder}>
                                                                                {val.accountHolder}
                                                                            </option>
                                                                        );
                                                                    })}
                                                                </datalist> */}
                                                                <Select
                                                                    className="basic-single"
                                                                    classNamePrefix="select"
                                                                    id={`${item.transactions.trn.trn_id}-${field.name}`}
                                                                    // value={bankData?.accountHolder}
                                                                    defaultInputValue={editedData[item.transactions.trn.trn_id]?.[field.name] || getFieldValue(item, field.name)}
                                                                    name="bankName"
                                                                    options={bankData}
                                                                    onChange={(selectedOption) => { handleEditChange(item.transactions.trn.trn_id, field.name, selectedOption.accountHolder) }}
                                                                    styles={customStyles}
                                                                    getOptionLabel={(option) => option.accountHolder}
                                                                    getOptionValue={(option) => option.accountHolder}
                                                                />
                                                            </>
                                                        ) : (
                                                            field.name === "transactions.trn.date" ? (
                                                                <input
                                                                    type="date"
                                                                    //disabled
                                                                    className="input_selector form-control p-0"
                                                                    style={{ width: "100px" }} // Initial width
                                                                    value={editedData[item.transactions.trn.trn_id]?.[field.name]}
                                                                    // value={editedData["transactions.trn.date"] ? editedData["transactions.trn.date"] : new Date()} // Or getInitialValue(field.name)
                                                                    // value={editedData["empty"]?.[field.name]}
                                                                    id={`${item._id}-${field.name}`}
                                                                    onChange={(e) => handleEditChange(item.transactions.trn.trn_id, field.name, e.target.value)}

                                                                // value={new Date().toLocaleDateString()}
                                                                />
                                                            ) : (
                                                                field.name === "transactions.trn.time" ? (
                                                                    <input
                                                                        type="time"
                                                                        //disabled
                                                                        value={editedData[item.transactions.trn.trn_id]?.[field.name]}
                                                                        className="input_selector form-control"
                                                                        style={{ width: "100px", padding: "0" }} // Initial width
                                                                        // value={editedData["empty"]?.[field.name]}
                                                                        // value={currentTime} // Or getInitialValue(field.name)
                                                                        id={`${item._id}-${field.name}`}
                                                                        onChange={(e) => handleEditChange(item.transactions.trn.trn_id, field.name, e.target.value)}
                                                                    />
                                                                ) : (
                                                                    field.name === "transactions.trn.doneBy" ? (
                                                                        <>
                                                                            {/* <input
                                                                                style={{ width: "100px" }}
                                                                                className="input_selector form-select"
                                                                                list={"donebyOptions"}
                                                                                // value={editedData["contactType"] || "Not Defined"}
                                                                                value={editedData[ item.transactions.trn.trn_id ]?.[field.name] || getFieldValue(item, field.name)}
                                                                                id={`${item._id}-${field.name}`}
                                                                                onChange={(e) => handleEditChange( item.transactions.trn.trn_id , field.name, e.target.value)}
                                                                            />

                                                                            <datalist id="donebyOptions">
                                                                                {doneByData.map((val) => {
                                                                                    return (
                                                                                        <option key={val.contactName} value={val.contactName}>
                                                                                            {val.contactName}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </datalist> */}
                                                                            <Select
                                                                                className="basic-single"
                                                                                classNamePrefix="select"
                                                                                id={`${item.transactions.trn.trn_id}-${field.name}`}
                                                                                // value={bankData?.accountHolder}
                                                                                defaultInputValue={editedData[item.transactions.trn.trn_id]?.[field.name] || getFieldValue(item, field.name)}
                                                                                name="doneByData"
                                                                                options={doneByData}
                                                                                onChange={(selectedOption) => { handleEditChange(item.transactions.trn.trn_id, field.name, selectedOption.contactName) }}
                                                                                styles={customStyles}
                                                                                getOptionLabel={(option) => option.contactName}
                                                                                getOptionValue={(option) => option.contactName}
                                                                            />
                                                                        </>
                                                                    ) : (

                                                                        field.name === "transactions.trn.totalBankbalance" || field.name === "transactions.trn.bankBalance" ? (

                                                                            <input
                                                                                type="text"
                                                                                className="input_selector form-control"
                                                                                id={`${item._id}-${field.name}`} // Unique ID for first row
                                                                                style={{ width: "100px" }} // Initial width
                                                                                readOnly
                                                                                value={editedData[item.transactions.trn.trn_id]?.[field.name] || getFieldValue(item, field.name)}
                                                                            // value={totalBankbalance} // Or getInitialValue(field.name)
                                                                            />
                                                                        ) : (
                                                                            <input
                                                                                type="text"
                                                                                className="input_selector form-control"
                                                                                style={{ width: "100px" }} // Initial width
                                                                                value={editedData[item.transactions.trn.trn_id]?.[field.name] || getFieldValue(item, field.name)}
                                                                                id={`${item._id}-${field.name}`}
                                                                                onChange={(e) => handleEditChange(item.transactions.trn.trn_id, field.name, e.target.value)}
                                                                            />
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )

                                                ) : (
                                                    <span>{getFieldValue(item, field.name) || "--"}</span>
                                                )}
                                            </td>
                                        ) : null)}

                                        {fields.some((field) => field.name === "verify") && (
                                            <td key="verify">
                                                <button
                                                    className={`btn btn-sm ${isActive("verify", item) ? "btn-success" : "btn-outline-success"
                                                        }`}
                                                    onClick={() => handleRowAction("verify", item)}
                                                >
                                                    <i class="bi bi-check2"></i>
                                                </button>{" "}
                                                <button
                                                    className={`btn btn-sm ${isActive("notverify", item) ? "btn-danger" : "btn-outline-danger"
                                                        }`}
                                                    onClick={() => handleRowAction("notverify", item)}
                                                >
                                                    <i class="bi bi-x-lg"></i>
                                                </button>{" "}
                                                {
                                                    isEditing[item.transactions.trn.trn_id] ? (
                                                        <button
                                                            className={`btn btn-sm ${isActive("edit", item) ? "btn-danger" : "btn-outline-info"
                                                                }`}
                                                            onClick={() => handleRowAction("edit", item)}
                                                        >
                                                            <i class="bi bi-pencil-square"></i>
                                                        </button>
                                                    ) : (
                                                        <button
                                                            className={`btn btn-sm ${isActive("edit", item) ? "btn-danger" : "btn-outline-info"
                                                                }`}
                                                            onClick={() => setIsEditing((prevEditing) => ({ ...prevEditing, [item.transactions.trn.trn_id]: true }))}
                                                        >
                                                            <i class="bi bi-pencil-square"></i>
                                                        </button>
                                                    )
                                                }

                                            </td>
                                        )}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={fields.length}>No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div >
            </div>
            <AddCustomerModel setTableReload={setTableReload} />

            <ul className="pagination mt-4 d-flex justify-content-center">
                {/* <button style={{ fontSize: "1em", padding: "2px 10px", fontWeight: "700" }} onClick={() => { currentPage > 1 && paginate(currentPage - 1) }} className=" btn-outline-primary page-link">
                    {"Prev"}
                </button>
                {Array.from({ length: Math.ceil(filteredData.length / itemsPerPage) }).map((_, index) => (
                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                        <button onClick={() => paginate(index + 1)} className="page-link">
                            {index + 1}
                        </button>
                    </li>
                ))}
                <button style={{ fontSize: "1em", padding: "2px 10px", fontWeight: "700" }} onClick={() => { currentPage < Math.ceil(filteredData.length / itemsPerPage) && paginate(currentPage + 1) }} className="btn-outline-primary page-link">
                    {"Next"}
                </button> */}
                <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalItems={totalItems} itemsPerPage={itemsPerPage} />
            </ul>
        </>
    );
};

export default DaybookTable;


