import axios from 'axios';
import toast from 'react-hot-toast';

const SetupAxiosInterceptors = () => {

    // Configure toast
    const TOAST_ID = 'global-error-toast';

    const showToast = (message, type = 'error') => {
        toast.dismiss(TOAST_ID); 
        toast[type](message, {
            id: TOAST_ID,
            duration: 3000
        });
    };

    // Response interceptor
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response && error.response.data) {
                if (error.response.data.message === 'Token expired') {
                    showToast('Your session has expired. Please log in again.');
                } else {
                    showToast(error.response.data.message || 'An error occurred');
                }
            }
            return Promise.reject(error);
        }
    );
};

export default SetupAxiosInterceptors;