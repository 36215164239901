// import 'dotenv/config';

import React, { useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import NavbarContext from "./context/NavbarContext";
import axios from "axios";
import getBaseUrl from "./services/connectionString";
import DaybookReport from "./pages/DaybookReport";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./css/global.css"
import LockIcon from '@mui/icons-material/Lock';

import { CreateListProvider } from "./context/CreateListContext.jsx";
import { Box, Button, Container, Paper, Typography } from "@mui/material";
import SetupAxiosInterceptors from "./services/axiosInterceptor.js";
import { ToastBar, Toaster } from "react-hot-toast";
// import Dashboard from "./pages/Dashboard";
// import Reports from "./pages/Reports";
// import Sidebar from "./components/Sidebar";
// import Blogs from "./pages/Blogs";
// import About from "./pages/About";
// import Newspaper from "./pages/Newspaper";
// import Terms from "./pages/Terms";
// import Privacy from "./pages/Privacy";
// import Settings from "./pages/Settings";
// import Payroll from "./pages/Payroll";
// import Settling from "./pages/Settling";
// import ExpensesTracker from "./pages/ExpensesTracker";
// import Payable from "./pages/Payable";
// import Receivable from "./pages/Receivable";
// import Result from "./pages/Result";
// import Report from "./pages/Report";
// import AddUser from "./pages/AddUser";
// import Transaction from "./pages/Transaction";
// import SettlingReport from "./pages/SettlingReport";
// import Test from "./pages/Test";
// import TopNavbar from "./components/TopNavbar";
// import Loading from "./components/Loading";

// Pages Files
// const Home = lazy(() => import("./pages/Home"));
const Home = lazy(() => import("./pages/DummyHome"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const Reports = lazy(() => import("./pages/Reports"));
const Blogs = lazy(() => import("./pages/Blogs"));
const About = lazy(() => import("./pages/About"));
const Newspaper = lazy(() => import("./pages/Newspaper"));
const Terms = lazy(() => import("./pages/Terms"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Settings = lazy(() => import("./pages/Settings"));
const Payroll = lazy(() => import("./pages/Payroll"));
const Bank = lazy(() => import("./pages/Bank"));
const Settling = lazy(() => import("./pages/Settling"));
const ExpensesTracker = lazy(() => import("./pages/ExpensesTracker"));
const Payable = lazy(() => import("./pages/Payable"));
const Receivable = lazy(() => import("./pages/Receivable"));
const Result = lazy(() => import("./pages/Result"));
const Report = lazy(() => import("./pages/Report"));
const AddUser = lazy(() => import("./pages/AddUser"));
const Transaction = lazy(() => import("./pages/Transaction"));
const SettlingReport = lazy(() => import("./pages/SettlingReport"));
const Test = lazy(() => import("./pages/Test"));
// Components File
const Sidebar = lazy(() => import("./components/Sidebar"));
const TopNavbar = lazy(() => import("./components/TopNavbar"));
const Loading = lazy(() => import("./components/Loading"));

// MY NEW COMPONENTS
const CreateList = lazy(() => import("./pages/CreateList"));
const ViewLists = lazy(() => import("./pages/ViewLists"));
const ListInfo = lazy(() => import("./pages/ListInfo"));

//Payment 
const PaymentMain = lazy(() => import("./pages/Payment/PaymentForm.jsx"))

const App = () => {
  let [validToken, setValidToken] = useState(false);
  const [sidebarToggle, setSidebarToggle] = useState(true);
  const [dropdown, setDropdown] = useState(null);
  const [currentUser, setCurrentUser] = useState()
  const [customer, setCustomer] = useState()

  const handleToggle = () => {
    setSidebarToggle(!sidebarToggle);
  };

  const mobileHandleToggle = () => {
    if (window.innerWidth <= 750) {
      setSidebarToggle(!sidebarToggle);
    }
  };

  const handleDropdownClick = (dropdownName) => {
    if (dropdown !== dropdownName) {
      setDropdown(dropdownName);
    }
  };

  const getUser = async () => {
    let user = await localStorage.getItem("currentUser");
    let customer = await localStorage.getItem("currentCustomer");
    console.log("curr", customer)
    if (!customer === undefined) {
      customer = await JSON.parse(customer)
      setCustomer(customer)
    }
    user = await JSON.parse(user);
    setCurrentUser(user)
  }


  useEffect(() => {
    getUser();
    //set JWT token here
    //console.loggetBaseUrl() + "api/users/verifyToken");
    axios.defaults.withCredentials = true;
    axios
      .get(getBaseUrl() + "api/users/verifyToken")
      .then((response) => {
        console.log(response.data)
        setValidToken(response.data.result);
      })
      .catch((error) => {
        console.log(error);
        setValidToken(error.response.data.result);
      });
  }, []);

  useEffect(() => {
    SetupAxiosInterceptors();
  }, []);

  return (
    <div className="d-flex overflow-x-hidden overflow-y-hidden h-max">
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <CreateListProvider>
            <NavbarContext>
              <Sidebar
                dropdown={dropdown}
                sidebarToggle={sidebarToggle}
                mobileHandleToggle={mobileHandleToggle}
                handleDropdownClick={handleDropdownClick}
              />
            </NavbarContext>
            <Routes>
              <Route path="/" index element={<Home />} />
              <Route path="/payment" element={<PaymentMain />} />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/privacy-policy" element={<Privacy />} />
              <Route path="/terms-and-conditions" element={<Terms />} />
              <Route path="/about" element={<About />} />
              <Route path="/news" element={<Newspaper />} />
              <Route
                path="/dashboard/*"
                element={
                  <DashboardRoutes
                    handleToggle={handleToggle}
                    sidebarToggle={sidebarToggle}
                    customer={customer}
                  />
                }
              />
              {/* <Route path="/dashboard" element={<Dashboard />} /> */}
              {/* <Route path="/add" element={<AddUser />} />
            <Route path="/reports" element={<Reports />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/payroll" element={<Payroll />} />
            <Route path="/daybook/payable" element={<Payable />} />
            <Route path="/daybook/receivable" element={<Receivable />} />
            <Route path="/expenses-tracker" element={<ExpensesTracker />} />
            <Route path="/match-settling/result" element={<Result />} />
            <Route path="/match-settling/report" element={<Report />} />
            <Route path="/settling/transaction" element={<Transaction />} />
            <Route path="/settling/report" element={<SettlingReport />} /> */}
              {/* <Route path="/test" element={<Test />} /> */}
            </Routes>
          </CreateListProvider>
        </BrowserRouter>
      </Suspense>
      <Toaster/>
    </div>
  );
};

const DashboardRoutes = ({ handleToggle, sidebarToggle, customer }) => {
  console.log("customer in DashboardRoutes:", customer);
  const [loadedCustomer, setLoadedCustomer] = useState(null);

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const storedCustomer = await localStorage.getItem("currentCustomer");
        if (storedCustomer) {
          const parsedCustomer = JSON.parse(storedCustomer);
          setLoadedCustomer(parsedCustomer);
          console.log("Loaded customer:", parsedCustomer);
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };

    if (!customer) {
      fetchCustomer();
    } else {
      setLoadedCustomer(customer);
    }
  }, [customer]);

  const ProtectedRoute = ({ element, permission }) => {
    if (!loadedCustomer) {
      // If customer data is still loading or doesn't exist, allow access
      return element;
    }
    // If customer exists, check permissions
    return loadedCustomer.permissions[permission] ? element : <Unauthorized />;
  };

  // const Unauthorized = () => <div>You don't have permission to access this page.</div>;

  return (

    <>
      <div className="premium_container">
        <TopNavbar handleToggle={handleToggle} sidebarToggle={sidebarToggle} />
        <Routes>
          {/* Highlight start */}
          <Route path="/full-report" element={<ProtectedRoute element={<Dashboard />} permission="dashboard" />} />
          <Route path="/add" element={<ProtectedRoute element={<AddUser />} permission="settings" />} />
          <Route path="/followup/create-list" element={<ProtectedRoute element={<CreateList />} permission="followUp" />} />
          <Route path="/followup/view-list" element={<ProtectedRoute element={<ViewLists />} permission="followUp" />} />
          <Route path="/followup/view-list/:id" element={<ProtectedRoute element={<ListInfo />} permission="followUp" />} />
          <Route path="/reports" element={<ProtectedRoute element={<Reports />} permission="dashboard" />} />
          <Route path="/settings" element={<ProtectedRoute element={<Settings />} permission="settings" />} />
          <Route path="/payroll" element={<ProtectedRoute element={<Payroll />} permission="payroll" />} />
          <Route path="/bank" element={<ProtectedRoute element={<Bank />} permission="bank" />} />
          <Route path="/daybook/deposit" element={<ProtectedRoute element={<Payable />} permission="daybank" />} />
          <Route path="/daybook/withdraw" element={<ProtectedRoute element={<Receivable />} permission="daybank" />} />
          <Route path="/daybook/daybook-report" element={<ProtectedRoute element={<DaybookReport />} permission="daybank" />} />
          <Route path="/expenses-tracker" element={<ProtectedRoute element={<ExpensesTracker />} permission="expenseTracker" />} />
          <Route path="/settling/transaction" element={<ProtectedRoute element={<Transaction />} permission="settling" />} />
          <Route path="/settling/report" element={<ProtectedRoute element={<SettlingReport />} permission="settling" />} />
          <Route path="/match-settling/result" element={<ProtectedRoute element={<Result />} permission="matchSettling" />} />
          <Route path="/match-settling/report" element={<ProtectedRoute element={<Report />} permission="matchSettling" />} />
          {/* Highlight end */}
        </Routes>
      </div>
    </>
  )
};

const Unauthorized = () => {
  const navigate = useNavigate();

  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 8, mb:8 }}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#f8f9fa',
          borderRadius: 2,
        }}
      >
        <LockIcon sx={{ fontSize: 60, color: 'error.main', mb: 2 }} />
        <Typography component="h1" variant="h4" gutterBottom>
          Access Denied
        </Typography>
        <Typography variant="body1" align="center" color="text.secondary" paragraph>
          Sorry, you don't have permission to access this page.
          Please contact your administrator if you believe this is an error.
        </Typography>
        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/dashboard/full-report')}
            sx={{ mr: 2 }}
          >
            Go to Dashboard
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate(-1)}
          >
            Go Back
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default App;
