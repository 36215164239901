import React, { useState } from 'react';

const Pagination = ({ totalItems, itemsPerPage, setCurrentPage, currentPage }) => {
  // const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const renderPaginationItems = () => {
    const startPage = currentPage > 3 ? currentPage - 2 : 1;
    // setStart(startPage);
    const endPage = Math.min(startPage + 4, totalPages);
    // setEnd(endPage);

    const pages = [];
    if (startPage > 1) {
      pages.push(
        <li
          className={`page-item`}
        >
          <span className="page-link" style={{ cursor: 'pointer' }}>
            ...
          </span>
        </li>
      )
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li
          key={i}
          className={`page-item ${i === currentPage ? 'active' : ''}`}
          onClick={() => handlePageChange(i)}
        >
          <span className="page-link" style={{ cursor: 'pointer' }}>
            {i}
          </span>
        </li>
      );
    }
    if (endPage < totalPages) {
      pages.push(
        <li
          className={`page-item`}
        >
          <span className="page-link" style={{ cursor: 'pointer' }}>
            ...
          </span>
        </li>
      )
    }
    return pages;
  };

  return (
    <div>
      <ul className="pagination">
        <li
          className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() =>
            setCurrentPage((prevPage) => Math.max(prevPage - 1, 1))
          }
        >
          <span className="page-link" style={{ cursor: 'pointer' }}>
            Previous
          </span>
        </li>
        {renderPaginationItems()}
        <li
          className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
          onClick={() =>
            setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages))
          }
        >
          <span className="page-link" style={{ cursor: 'pointer' }}>
            Next
          </span>
        </li>
      </ul>
      {/* <div className="form-group">
        <select
          className="form-control"
          value={currentPage}
          onChange={(e) => setCurrentPage(parseInt(e.target.value))}
        >
          {[...Array(totalPages)].map((_, index) => (
            <option key={index + 1} value={index + 1}>
              Page {index + 1}
            </option>
          ))}
        </select>
      </div> */}
    </div>
  );
};

export default Pagination;
