import { useCallback, useRef } from "react";

export default useFocusNext => {
const controls = useRef([]);

  const handler = (event) => {
      if (event.ctrlKey) {
          const isForward = event.key === 'ArrowRight';
          const isBackward = event.key === 'ArrowLeft';
          if (isForward || isBackward) {
              event.preventDefault();
              controls.current = controls.current
                  .filter((control) => document.body.contains(control))
                  .sort((a, b) =>
                      a.compareDocumentPosition(b) & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1
                  );

              const index = controls.current.indexOf(event.target);
              const nextIndex = (index + (isForward ? 1 : -1) + controls.current.length) % controls.current.length;
              const next = controls.current[nextIndex];
              next && next.focus();
          }
      }
  };

  return useCallback((element) => {
      if (element && !controls.current.includes(element)) {
          controls.current.push(element);
          element.addEventListener('keydown', handler);
      }
  }, []);
}
