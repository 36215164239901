import React from 'react';

const DeleteConfirmationPopup = ({ onConfirm, onCancel, customerName }) => {
    return (
        <div className={`modal fade`}
            id="deleteModal"
            tabIndex="-1"
            aria-labelledby="deleteModalLabel"
            style={{ zIndex: "99991" }}>

            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Delete Customer Details</h5>
                    </div>
                    <div
                        className="modal-body"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <p>Are you sure you want to delete the account of {customerName}?</p>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className={`btn btn-primary`}
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className={`btn btn-danger`}
                            onClick={onConfirm}
                        >
                            Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteConfirmationPopup;

/*
<div
        className={`modal fade`}
        id="editModal"
        tabIndex="-1"
        aria-labelledby="editModalLabel"
        style={{ zIndex: "99991" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Editing Bank Details</h5>
            </div>
            <div
              className="modal-body"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {renderStep()}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className={`btn btn-primary`}
                onClick={handleSubmit}
              >
                Submit
              </button>
              <button
                type="button"
                ref={myButtonRef}
                data-bs-dismiss="modal"
                style={{ display: "none" }}
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div className="modal-content">
        <p>Are you sure you want to delete the account of {customerName}?</p>
        <div className="buttons">
          <button onClick={onCancel}>Cancel</button>
          <button onClick={onConfirm}>Delete</button>
        </div>
      </div>
*/